import React, { useState } from "react";
import { FaBars, FaTimes, FaHome, FaUser, FaProjectDiagram, FaBriefcase, FaEnvelope } from "react-icons/fa";

const NavBar = () => {
  const [nav, setNav] = useState(false);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    setNav(false);
  };

  const links = [
    { id: 1, name: "Home", sectionId: "home", icon: <FaHome size={24} /> },
    { id: 2, name: "About", sectionId: "about", icon: <FaUser size={24} /> },
    { id: 3, name: "projects", sectionId: "projects", icon: <FaProjectDiagram size={24} /> },
    { id: 4, name: "Experience", sectionId: "experience", icon: <FaBriefcase size={24} /> },
    { id: 5, name: "Contact", sectionId: "contact", icon: <FaEnvelope size={24} /> },
  ];

  return (
    <div className="relative">
      <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed top-0 z-50 border-b border-gray-800">
        <div>
          <h1 className="text-2xl font-signature ml-2 text-orange-500">SaRtHaK</h1>
        </div>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex">
          {links.map(({ id, name, sectionId }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize text-gray-300 hover:text-orange-500 transition ease-linear duration-200"
              onClick={() => scrollToSection(sectionId)}
            >
              {name}
            </li>
          ))}
        </ul>

        {/* Mobile Menu Icon */}
        <div
          onClick={() => setNav(!nav)}
          className="cursor-pointer text-gray-300 pr-4 z-10 md:hidden"
        >
          {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
      </div>

      {/* Backdrop */}
      {nav && (
        <div
          className="fixed inset-0 bg-black/60 z-30"
          onClick={() => setNav(false)} // Close drawer on backdrop click
        />
      )}

      {/* Mobile Navigation - Vertical Drawer */}
      <div
        className={`fixed top-0 right-0 w-3/4 h-full bg-black text-white z-40 transform ${
          nav ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-end p-6">
          <FaTimes size={30} className="cursor-pointer text-orange-500" onClick={() => setNav(false)} />
        </div>
        <ul className="flex flex-col items-center mt-20">
          {links.map(({ id, icon, sectionId }) => (
            <li
              key={id}
              className="py-6 cursor-pointer hover:text-orange-500 transition ease-linear duration-200"
              onClick={() => scrollToSection(sectionId)}
            >
              {icon}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;

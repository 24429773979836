import React from 'react';
import { FaGithub } from 'react-icons/fa';

function App() {
  const projects = [
    {
      name: "Dark Web Monitoring and Analysis (DARC)",
      url: "https://github.com/sarthak4399/DARC",
      date: "Jun 2024",
      description: "Utilizes advanced technologies like the Scrappy framework and the Tor network to explore and analyze .onion sites. Enhances cyber security efforts globally.",
      skills: ["Scrapy", "Tor Network", "Cyber Security"]
    },
    {
      name: "Websites Monitoring and Analysis Tool",
      url: "https://github.com/sarthak4399/GOV_WEB_MONITOR_GPCSSI2024",
      date: "Jun 2024",
      description: "Developed a sophisticated solution for tracking web content changes, designed to meet law enforcement needs with real-time processing and reporting.",
      skills: ["Web Scraping", "Automation", "Python"]
    },
    {
      name: "KrishiMitra",
      url: "https://github.com/sarthak4399/krishimitra-dev",
      date: "Dec 2023",
      description: "Developing a user-friendly WhatsApp chatbot to assist farmers and dealers, focusing on preventing wastage in agriculture.",
      skills: ["Flask", "Python", "AWS (S3, EC2)"]
    },
    {
      name: "FSI bash",
      url: "https://github.com/sarthak4399/FSI_bash",
      date: "Jan 2023",
      description: "Developed a tool to help system administrators analyze system information quickly with structured output.",
      skills: ["Bash", "Shell Scripting", "Linux"]
    },
    {
      name: "TaskStack: A React Native application",
      url: "https://play.google.com/store/apps/details?id=com.taskstack&pcampaignid=web_share",
      date: "Jul 2023",
      description: "Simplifies project and task management with features for project creation, team management, and task assignments.",
      skills: ["React Native", "JavaScript", "Firebase", "MongoDB", "ExpressJS"]
    },
    {
      name: "Index IT: A MERN Web Application",
      url: "https://github.com/username/index-it",
      date: "Apr 2023",
      description: "Streamlines communication and complaint management between hostel staff and maintenance personnel.",
      skills: ["Next.js", "Node.js", "Express.js", "MongoDB", "JWT Security"]
    },
    {
      name: "Rmageddon Web Portal: A Web Application",
      url: "https://rmageddon.vercel.app",
      date: "Mar 2023",
      description: "Provides an online registration platform for Rmageddon-23 robotics competition organized by RNXG club.",
      skills: ["React.js", "Node.js", "JavaScript", "Tailwind CSS"]
    },
    // {
    //   name: "Autonomous Navigation Mobile Robot (AMR)",
    //   url: "https://github.com/username/amr",
    //   date: "Ongoing",
    //   description: "Designed for industrial environments with advanced mapping capabilities, instrument operations, and SCADA system integration.",
    //   skills: ["ROS", "Python", "OpenCV", "Unity Engine"]
    // },
    {
      name: "Autonomous Guided Vehicle (AGV) Development",
      url: "https://drive.google.com/drive/folders/1I3NVRrs4DjFIwk9YpIbxb1i8nBgFp_D9",
      date: "Feb 2024",
      description: "Engineered an AGV with advanced navigation, shortest path algorithm, PID control, and real-time data transmission.",
      skills: ["Python3", "Arduino", "Flask", "OpenCV", "PID"]
    },
    {
      name: "Luminosity Drone (Simulation)",
      url: "https://github.com/sarthak4399/luminosity_drone_EYRC",
      date: "Sep 2023",
      description: "Developed a ROS-based drone for thermal landscape monitoring with advanced simulation capabilities.",
      skills: ["ROS", "Gazebo Simulation", "Python", "OpenCV", "Control Systems"]
    }
  ];

  return (
    <div className="bg-black text-white p-8" id='projects'>
      <h1 className="text-4xl font-extrabold mb-6 border-b-4 border-orange-500 pb-2">My Projects</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <div key={index} className="bg-gray-800 bg-opacity-30 backdrop-blur-md rounded-lg shadow-lg p-6 border border-gray-700">
            <div className="flex items-center mb-4">
              <div className="bg-orange-500 rounded-full w-15 h-15 flex items-center justify-center">
                <FaGithub size={24} className="text-white" />
              </div>
              <h2 className="ml-4 text-2xl font-bold">{project.name}</h2>
            </div>
            <p className="text-gray-300 mb-4">{project.description}</p>
            <p className="text-gray-400 mb-4">{project.date}</p>
            <div className="flex flex-wrap gap-2 mb-4">
              {project.skills.map((skill, i) => (
                <span key={i} className="bg-gray-700 px-3 py-1 rounded-md text-sm">
                  {skill}
                </span>
              ))}
            </div>
            <a
              href={project.url}
              className="block text-orange-400 hover:text-orange-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;

import React from "react";
import profileImage from "../Assets/Subject.png"; // Replace with your actual profile image
import { FaGithub, FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";

const About = () => {
  const scrollToContact = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  }

  const scrollToProjects = () => {
    document.getElementById("projects").scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div id="about" className="w-full min-h-screen flex flex-col lg:flex-row bg-black text-white relative overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="w-full h-full bg-gradient-to-r from-black via-gray-900 to-black opacity-60"></div>
      </div>
      <div className="lg:w-1/2 flex items-center justify-center p-8 lg:p-16 bg-transparent relative z-10">
        <div className="relative group">
          <img
            src={profileImage}
            alt="Sarthak Khandare"
            className="w-60 h-60 lg:w-96 lg:h-96 object-contain rounded-full shadow-xl transform transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-orange-500 to-orange-400 rounded-full opacity-0 group-hover:opacity-40 transition-opacity duration-500 flex items-center justify-center">
            <p className="text-xl sm:text-2xl font-bold text-white">Hello, I'm Sarthak!</p>
          </div>
        </div>
      </div>

      {/* Right Side - Text */}
      <div className="lg:w-1/2 flex flex-col justify-center p-8 lg:p-16 relative z-10 text-white">
        <div className="pb-6 lg:pb-8">
          <p className="text-4xl sm:text-5xl font-extrabold inline border-b-4 border-orange-500 tracking-wide">
            About Me
          </p>
        </div>
        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed mt-6">
          I'm <span className="text-orange-400 font-bold">Sarthak Sanjiv Khandare</span>, a passionate <span className="text-orange-400 font-bold">cybersecurity enthusiast</span> and experienced CTF player. I thrive on tackling challenging problems and pushing my skills further every day.
        </p>
        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed mt-6">
          My expertise spans across <span className="text-orange-400 font-bold">React.js, React Native, MongoDB, Express,</span> and <span className="text-orange-400 font-bold">Node.js</span>. I love crafting applications that are not only scalable but also secure and reliable.
        </p>
        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed mt-6">
          My mission is to merge my knowledge of cybersecurity and full-stack development to build cutting-edge solutions that protect and empower users in the digital world.
        </p>
        <div className="mt-8 flex space-x-4">
          <a
            href="#contact"
            className="bg-orange-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition-colors"
            onClick={scrollToContact}
          >
            Get in Touch
          </a>
          <a
            href="#projects"
            className="border border-orange-500 text-orange-500 px-6 py-3 rounded-full text-lg font-semibold hover:bg-orange-500 hover:text-white transition-colors"
            onClick={scrollToProjects}
          >
            View My Work
          </a>
        </div>

        {/* Social Media Links */}
        <div className="mt-8 flex space-x-4">
          <a
            href="https://github.com/sarthak4399" // Replace with your GitHub URL
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-orange-500 transition-colors"
          >
            <FaGithub size={30} />
          </a>
          <a
            href="https://linkedin.com/in/sarthak-khandare-b13570199" // Replace with your LinkedIn URL
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-orange-500 transition-colors"
          >
            <FaLinkedin size={30} />
          </a>
          {/* <a
            href="https://twitter.com/username" // Replace with your Twitter URL
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-orange-500 transition-colors"
          >
            <FaTwitter size={30} />
          </a> */}
          <a
            href="https://instagram.com/_sarth21_" // Replace with your Instagram URL
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-orange-500 transition-colors"
          >
            <FaInstagram size={30} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;

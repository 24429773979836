import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import SocialLinks from "./Components/SocialLinks";
import About from "./Components/About";
import Portfolio from "./Components/Portfolio";
import Experience from "./Components/Experiances";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer"
import Achievements from "./Components/Achievements";
function App() {
  return (
    <div>
      <NavBar />

      <Home />

      <About />

      <Portfolio />

      <Experience />

      <Achievements />

      <Contact />

      {/* <SocialLinks />
      <Footer/> */}
    </div>
  );
}

export default App;

import React from "react";
import { Chrono } from "react-chrono";
import { motion } from "framer-motion";
import GPCSSI from "../Assets/GPCSSI_LOGO.png"; // Import your image
import UNIFLIK from "../Assets/uniflik_logo.jpeg"; // Import your image
import SECULIBRIUM from "../Assets/Seculibrium-Logo-black.png"; // Import your image
// Updated work experiences
const workExperiences = [
  {
    title: "Cyber Security Intern",
    cardTitle: "Gurugram Cyber Crime Department",
    cardSubtitle: "June 2024 - July 2024",
    cardDetailedText: "Work with Cyber crime department and law enforcement agency, focusing on Security Tools Development. Developed tools for Government Websites Monitoring and Analysis and Dark Web Monitoring and Analysis.",
    cardImage: GPCSSI // Reference the imported image
  },
  {
    title: "Developer Intern",
    cardTitle: "Uniflik",
    cardSubtitle: "November 2023 - December 2023",
    cardDetailedText: "Worked on database modelling and deployment. Improved database performance by 20% using the Express.js framework and SQL Database Modelling.",
    cardImage: UNIFLIK // Example image URL
  },
  {
    title: "Cyber Security Engineer",
    cardTitle: "Seculibrium Services and Solutions Pvt. Ltd.",
    cardSubtitle: "August 2023 - November 2023",
    cardDetailedText: "Worked remotely with Wazuh Tool and reported insights from SIEM events. Analyzed security events, identified vulnerabilities, and recommended remediation steps using SIEM, Threat Intelligence, Python, and Linux.",
    cardImage: SECULIBRIUM  // Example image URL 
  }
];

const Experience = () => {
  return (
    <div className="bg-black text-white py-16" id="experience">
      <div className="max-w-screen-lg mx-auto px-6">
        <div className="text-center mb-12">
          <p className="text-4xl font-extrabold border-b-4 border-orange-500 inline-block pb-3">Work Experience</p>
          <p className="mt-4 text-lg font-light text-gray-400">A visual timeline of my professional experiences and roles across various companies.</p>
        </div>

        {/* Timeline Section */}
        <div className="relative">
          <Chrono
            items={workExperiences}
            mode="VERTICAL"
            theme={{
              primary: "#FF6F00", // Orange for timeline markers
              secondary: "#2d2d2d", // Darker black for cards and background
              cardBgColor: "#1a1a1a", // Dark gray for card background
              titleColor: "#FFFFFF", // White for titles
              subtitleColor: "#FF6F00", // Orange for subtitles
              itemBgColor: "#1a1a1a", // Dark gray for items background
            }}
          >
            {workExperiences.map((experience, index) => (
              <motion.div
                key={index}
                className="bg-gray-900 p-6 rounded-lg shadow-md flex items-start"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
              >
                {/* Image section */}
                {experience.cardImage && (
                    experience.cardImage === 'SECULIBRIUM' ? (
                      <img
                        src={experience.cardImage}
                        alt={experience.title}
                        className="w-20 h-20 object-cover rounded-full mr-4"
                      />
                      
                    ) : (
                      <img
                        src={experience.cardImage}
                        alt={experience.title}
                        className="w-20 h-20 object-cover rounded-full mr-4"
                      />
                    )
                  )}
                
                <div>
                  <h3 className="text-2xl font-semibold mb-2">{experience.title}</h3>
                  <p className="text-orange-500 text-sm mb-1">{experience.cardTitle}</p>
                  <p className="text-gray-400 text-sm mb-4">{experience.cardSubtitle}</p>
                  <p className="text-gray-300">{experience.cardDetailedText}</p>
                </div>
              </motion.div>
            ))}
          </Chrono>
        </div>
      </div>
    </div>
  );
};

export default Experience;

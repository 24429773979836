import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <div name="contact" className="bg-black text-white w-full h-screen flex" id="contact">
      {/* Left Side: Contact Form */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-8 bg-black">
        <div className="bg-gray-900 border border-gray-700 rounded-lg p-6 w-full max-w-lg shadow-lg">
          <p className="text-2xl font-bold inline border-b-4 border-orange-500 mb-4">
            Contact Me
          </p>
          <form
            action="https://getform.io/f/c67b1512-eb29-4944-baf6-2187839e3bc9"
            method="POST"
            className="flex flex-col"
          >
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="p-2 bg-gray-800 border-2 border-gray-700 rounded-md focus:outline-none mb-4 text-white"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="p-2 bg-gray-800 border-2 border-gray-700 rounded-md text-white focus:outline-none mb-4"
            />
            <textarea
              name="message"
              placeholder="Your Message"
              rows="6"
              className="p-2 bg-gray-800 border-2 border-gray-700 rounded-md text-white focus:outline-none mb-4"
            ></textarea>
            <button
              type="submit"
              className={`group text-white w-full px-6 py-3 flex items-center rounded-md bg-orange-500 border border-orange-500 shadow-md hover:bg-white hover:text-orange-500 transition duration-300`}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>

      {/* Right Side: Visual and Contact Info */}
      <div className="hidden md:flex w-1/2 items-center justify-center bg-center p-8">
        <div className="text-center bg-black bg-opacity-50 p-6 rounded-lg">
          <p className="text-4xl font-bold text-white mb-4">Let's Connect</p>
          <div className="flex flex-col items-center space-y-4">
            <div className="flex items-center text-white">
              <FaEnvelope size={24} className="mr-2" />
              <span>contact@conduitsystems.me</span>
            </div>
            <div className="flex items-center text-white">
              <FaMapMarkerAlt size={24} className="mr-2" />
              <span>Nanded</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

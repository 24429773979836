import React, { useEffect, useState } from "react";

const Terminal = () => {
  const [text, setText] = useState("");
  const content = [
    "$ PASSIONATE ABOUT BUILDING SECURE SYSTEMS",
    "$ PROBLEM SOLVER AND CODE ENTHUSIAST",
    "$ CONTINUOUS LEARNER AND TECH ENTHUSIAST",
    "$ BELIEVER IN THE POWER OF TECHNOLOGY",
  ];

  useEffect(() => {
    let currentIndex = 0;
    let currentText = "";
    let timeout;
    const type = () => {
      if (currentIndex === content.length) {
        return;
      }
      currentText = content[currentIndex].slice(0, currentText.length + 1);
      setText(currentText);

      if (currentText === content[currentIndex]) {
        currentIndex++;
        clearTimeout(timeout);
        timeout = setTimeout(type, 1000); // Delay before typing the next line
      } else {
        timeout = setTimeout(type, 70); // Typing speed
      }
    };

    type();
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="flex justify-start">
      <div className="bg-black px-4 py-6 rounded-lg shadow-lg  lg:max-w-[500px] w-[22rem] ">
        <div className="ml-4">
          <pre className="text-orange-500 whitespace-pre-wrap">
            <code>{text}</code>
          </pre>
          <div className="mt-4 flex items-center">
            <span className="text-yellow-300 pr-2">$</span>
            <input
              type="text"
              className="flex-1 bg-transparent text-orange-300 px-1 focus:outline-none"
              placeholder="Type a command..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminal;

import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import zupeeCTFImage from "../Assets/GPCSSI.png"; // Replace with actual image paths
import smartIndiaHackathonImage from "../Assets/SIH.jpeg";

// Sample data
const achievements = [
  {
    title: "Ranked 2nd in Zupee CTF",
    description: "Achieved 2nd place in the Zupee Capture The Flag competition, showcasing exceptional problem-solving and cybersecurity skills.",
    image: zupeeCTFImage
  },
  {
    title: "Ranked 1st in Smart India Hackathon",
    description: "Secured 1st place in the Smart India Hackathon, demonstrating innovative solutions and team collaboration in a national competition.",
    image: smartIndiaHackathonImage
  }
];

// Slider settings
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true
};

const Achievements = () => {
  return (
    <div className="bg-black text-white py-16" id="achievements">
      <div className="max-w-screen-lg mx-auto px-6">
        <div className="text-center mb-12">
          <p className="text-4xl font-extrabold border-b-4 border-orange-500 inline-block pb-3">
            Achievements
          </p>
          <p className="mt-4 text-lg font-light text-gray-400">
            Here are some of my notable achievements and accomplishments in various competitions and events.
          </p>
        </div>

        {/* Carousel Container */}
        <div className="relative">
          <Slider {...sliderSettings}>
            {achievements.map((achievement, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <img
                  src={achievement.image}
                  alt={achievement.title}
                  className="w-full h-30 object-contain rounded-md mb-4"
                />
                <h3 className="text-2xl font-semibold mb-2">{achievement.title}</h3>
                <p className="text-gray-400 text-center">{achievement.description}</p>
              </motion.div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Achievements;

import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Terminal from "./Terminal";
import video from "../Assets/4760-179739327_medium.mp4";

const Home = () => {
  const navigate = () => {
    const about = document.getElementById("about");
    about.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="home" className="relative h-screen w-full">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black/60 flex items-center justify-center">
        <div className="text-center p-4 sm:p-6 bg-black/80 rounded-lg shadow-lg max-w-sm sm:max-w-md mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold text-white">
            Hi There!
          </h2>
          <h3 className="text-2xl sm:text-3xl font-bold text-white mt-2 sm:mt-4">
            I'm Sarthak Khandare
          </h3>
          <p className="text-white py-4 sm:py-6 text-lg sm:text-xl">
            I'm a Full Stack Developer | React | Node.js | Express | MongoDB | Tailwind
          </p>
          <div className="mt-4 sm:mt-6">
            <Terminal />
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2" onClick={navigate}>
        <MdOutlineKeyboardArrowDown
          size={30}
          className="text-orange-500 animate-bounce cursor-pointer"
          style={{ border: '1px solid white', borderRadius: '9999px', padding: '4px' }}
        />
      </div>
    </div>
  );
};

export default Home;
